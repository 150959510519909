main {
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  line-height: 1.6;

  @include themify($themes) {
    color: themed("colorText");
  }
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-black;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
  }
}

// a {
//   color: $color-blue;
//   transition: all 0.3s;

//   &:hover {
//     text-decoration: none;
//     color: $color-blue-hover;
//   }
// }

// img {
//   width: 100%;
// }
