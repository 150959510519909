$font-color: #646777;
$font-size: 13px;

.editable__array-container {
  border: 1px solid #ccc;

  & .editable__array-header {
    display: flex;
    background: #f2f4f7;
    align-items: center;

    & .editable__array-header__cell {
      text-align: center;
      font-family: "Roboto", sans-serif;
      font-size: $font-size;
      color: $font-color;
      width: -webkit-fill-available;
    }
  }

  .editable__array-row {
    display: flex;

    & input:disabled {
      background-color: white !important;
    }
  }

  .editable__array-legend {
    text-align: center;
    color: $font-color;
    font-size: $font-size;
  }
}

.editable__array-vertical {
  position: relative;
  width: 100%;
}
