.box__container {
  display: flex;
  flex-direction: column;

  .box__editor {
    border: 1px solid black;
    height: 40rem;
    border-radius: 0.5rem;
    overflow: auto;

    .box__body {
      margin: 1rem;
    }
  }

  .box_button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0.5rem !important;

    button {
      margin-bottom: 0 !important;
    }
  }
}
