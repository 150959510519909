.btnContainer {
  width: 100%;
  text-align: end;
  position: relative;
  z-index: 1;

  .absolute {
    position: absolute;
    z-index: 100;
    right: 0;

    @media screen and (max-width: 865px) {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }

    button {
      margin: 1.25rem !important;
    }
  }
}
