.loader {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inside--thing {
  height: auto;
  padding: 50px 0;
}
