@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

@font-face {
  font-family: "Stolzl";
  src: url("../../assets/fonts/Stolzl/Stolzl_Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Stolzl";
  src: url("../../assets/fonts/Stolzl/Stolzl_Book.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Stolzl";
  src: url("../../assets/fonts/Stolzl/Stolzl_Light.ttf") format("truetype");
  font-weight: lighter;
}
