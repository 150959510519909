.box__image {
  display: flex;
  justify-content: center;
  margin: 1rem 0 1rem 0;

  img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 25rem;
    max-height: 25rem;
  }
}
